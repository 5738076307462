<template>
  <div
    v-if="disclaimers?.length"
    class="disclaimerInfo"
    :class="isMobile ? 'sidebarGroup' : 'container diWeb'"
  >
    <div
      class="title"
      :class="!showDisclaimer ? '' : 'active'"
      @click="showDisclaimer = !showDisclaimer"
    >
      <span :class="isMobile ? '' : 'text-underline'">**Disclaimers</span>
      <q-icon
        size="1.5rem"
        :name="showDisclaimer ? 'remove' : 'add'"
        v-if="isMobile"
      />
      <q-icon
        size="1.5rem"
        :name="showDisclaimer ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
        v-else
      />
    </div>
    <q-slide-transition>
      <div class="content" v-show="showDisclaimer">
        <div class="text">
          <ul class="bulletList">
            <li
              v-for="(disclaimer, index) in disclaimers"
              :key="`${disclaimer.id}-${index}`"
            >
              <div>
                <div
                  class="text-subtitle2 text-weight-bold"
                  v-html="disclaimer.title"
                />
                <div class="text-subtitle2" v-html="disclaimer.description" />
              </div>
            </li>
          </ul>
          <!-- <div
            class="q-my-sm"
            :class="isMobile ? 'text-dark' : ''"
            v-for="(disclaimer, index) in disclaimerList"
            :key="index"
          >
            <div class="text-subtitle2 text-weight-bold">{{
              disclaimer.title
            }}</div>
            <div class="text-subtitle2" v-html="disclaimer.description" />
          </div> -->
        </div>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
export default {
  name: 'DisclaimerInfo',
  data() {
    return {
      showDisclaimer: false,
    }
  },
  computed: {
    disclaimers() {
      return this.commonStateByKey('disclaimers') || []
    },
  },
}
</script>

<style lang="scss">
.disclaimerInfo {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  ul {
    padding-top: 10px;
    li {
      font-size: 13px;
      padding-bottom: 10px;
    }
  }

  &.sidebarGroup {
    .title {
      padding: 0;
    }
    .text {
      padding: 8px 0;
    }
  }
  &.diWeb {
    .title {
      background: #292929;
      max-width: 140px;
      margin: 0 auto;
      padding: 8px 12px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .text {
      background: #292929;
      padding: 12px 16px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
    }
    ul {
      li {
        color: #fff;
      }
    }
  }
}
</style>
